@import url('https://fonts.cdnfonts.com/css/tt-norms-pro');

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.containerFlow {
    @apply flex flex-col w-full min-h-screen justify-between border-teal-800;
}

.containerCol {
    @apply w-full flex flex-col;
}


.gridSystem {
    @apply grid gap-6 sm:grid-cols-2 lg:grid-cols-3 w-full border-teal-500;
}

.lineUp {
    @apply appearance-none block w-full px-3 py-2 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm;
}

@layer utilities {
    .scrollbar-gone::-webkit-scrollbar {
        width: 0px;
    }
}

@layer base {
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

:focus-visible {
    outline: none;
}

.se-gradient {
    background: transparent linear-gradient(180deg, #F04E23 0%, #F68B1F 100%) 0% 0% no-repeat padding-box;
}

.se-gradient-2 {
    background: transparent linear-gradient(270deg, #F04E23 0%, #F68B1F 100%) 0% 0% no-repeat padding-box;
}

.se-gradient-3 {
    background: transparent linear-gradient(90deg, #F04E23 0%, #F68B1F 100%) 0% 0% no-repeat padding-box;
}

.se-gradient-4 {
    background: transparent linear-gradient(62deg, #F04E23 0%, #F68B1F 100%) 0% 0% no-repeat padding-box;
}

button:disabled {
    background: #bfc0bf
}

body :where(.vi__character) {
    border-color: #060203;
    border-width: 1px;
    border-style: solid;
    border-radius: 6px;
    background: #ffffff;
    width: 40px;
    flex-grow: 0;
    flex-basis: auto;
    font-size: 24px;
    font-weight: bold;
    line-height: 60px;
    font-style: normal;
    text-align: center;
    color: #070203;
    display: flex;
    justify-content: center;
    align-items: center;
}

body :where(.vi__wrapper) {
    width: 100%;
}

body :where(.vi__container) {
    gap: 5px;
    justify-content: center;
    width: 100%;
}

body :where(.vi__character--selected) {
    outline: none;
}

body :where(.vi__character--inactive) {
    border-color: #bfc0bf;
}

body :where(.vi__character):nth-child(3) {
    margin-right: 25px;
    position: relative;
}

body :where(.vi__character):nth-child(3):after {
    content: '';
    position: absolute;
    right: -25px;
    width: 18px;
    height: 1px;
    border-color: #bfc0bf;
    border-width: 1px;
    border-style: solid;
}

.se-table table tr:last-child {
    border: none;
}

@media screen and (max-width: 1023px) {
    .se-table table,
    .se-table tbody,
    .se-table th,
    .se-table td,
    .se-table tr {
        display: block;
    }

    .se-table td.hidden, .se-table th.hidden {
        display: none;
    }

    .se-table td,
    .se-table th {
        padding-left: 180px;
        position: relative;
    }

    .se-table td::before,
    .se-table th::before {
        content: attr(data-label);
        position: absolute;
        left: 0;
        width: 160px;
        font-weight: bold;
        text-align: left;
        font-size: 14px;
    }

    .se-table tr td:first-child {
        padding-top: 20px;
    }
}

@media screen and (max-width: 400px) {
    .se-table td,
    .se-table th {
        padding-left: 150px;
        position: relative;
    }
}

.se-border-dashed {
    height: 1.2px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='6' stroke-dasharray='2%2c14' stroke-dashoffset='12' stroke-linecap='square'/%3e%3c/svg%3e");
}

.nav-item.active {
    color: #E3672A
}

@media screen and (min-width: 2000px) {
    .home-banner {
        left: 50% !important;
    }
}

.btn-prev.swiper-button-disabled, .btn-next.swiper-button-disabled {
    background: transparent;
    opacity: 0.3;
}

.dark-input .error-message {
    background: transparent;
}

.input-with-right-addon .error-message-block {
    bottom: -16px;
    top: auto;
    left: 0;
}

.vi__wrapper input:focus {
    --tw-ring-color: none;
}
